<template>
  <div class="free-subtitles-page">
    <header>
      <nav class="navbar">
        <a href="/"> <img src="@/assets/logo.jpg" alt="Logo" class="logo" /></a>
        <ul class="menu">
          <li><a href="/">Home</a></li>
          <li><a href="/pricing">Pricing</a></li>
          <li><a href="/free-subtitle">Free Subtitles</a></li>
          <li>
            <router-link to="/subtitle-generator">
              <button class="generate-now-button">Generate Now</button>
            </router-link>
          </li>
        </ul>
      </nav>
    </header>

    <section class="subtitle-section">
      <h1>Free Subtitles</h1>
      <table class="video-table" v-if="videos.length">
        <thead>
          <tr>
            <th>#</th>
            <th>Video</th>
            <th>Watch Video</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(video, index) in videos" :key="index">
            <td>{{ (currentPage - 1) * rowsPerPage + index + 1 }}</td>
            <td>
              <img
                :src="getThumbnail(video.url)"
                alt="Thumbnail"
                class="thumbnail"
              />
            </td>

            <td>
              <a
                :href="`/subtitle-view?accessId=${video.access_url}&orderId=${
                  video.order_id
                }&videoId=${getYouTubeVideoId(video.url)}`"
                target="_blank"
                class="watch-link"
                >Watch</a
              >
            </td>
          </tr>
        </tbody>
      </table>

      <p v-else>No videos available.</p>

      <div class="pagination" v-if="totalPages > 1">
        <button @click="prevPage" :disabled="currentPage === 1">
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next
        </button>
      </div>
    </section>

    <footer class="footer">
      <p>
        Made by
        <a href="https://x.com/JoeSetthawut">Joe Setthawut</a>
      </p>
    </footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      videos: [],
      currentPage: 1,
      rowsPerPage: 5,
      totalVideos: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalVideos / this.rowsPerPage);
    },
  },
  methods: {
    getYouTubeVideoId(url) {
      const match = url.match(
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*[?&]v=([^&]+)/
      );
      return match ? match[1] : null;
    },

    getThumbnail(url) {
      const videoId = new URL(url).searchParams.get("v");
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    },
    fetchVideos() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/free-subtitle`, {
          params: {
            page: this.currentPage,
            limit: this.rowsPerPage,
          },
        })
        .then((response) => {
          const data = response.data;
          // Assuming the response structure contains videos and total count
          this.videos = data.subtitles.map((video) => ({
            title: "",
            url: video.url,
            access_url: video.access_url,
            order_id: video.order_id,
            subtitlePreview: video.subtitlePreview || "No subtitles available",
          }));
          this.totalVideos = data.total;
        })

        .catch((error) => {
          console.error("Error fetching videos:", error);
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchVideos();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchVideos();
      }
    },
  },
  created() {
    this.fetchVideos();
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282828;
  padding: 10px 20px;
}

.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin-left: 20px;
}

.navbar a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.subtitle-section {
  padding: 40px;
  text-align: center;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ff0000;
}

.video-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.video-table th,
.video-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}

.pagination button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.footer {
  background-color: #282828;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 50px;
}

.footer a {
  color: #ff0000;
  text-decoration: none;
  font-weight: bold;
}

.logo {
  height: 50px;
}
.thumbnail {
  width: 120px;
  height: auto;
  display: block;
  margin-bottom: 10px;
}

/* Generate Now Button styling */
.generate-now-button {
  background-color: red;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-left: 20px; /* Space between menu items and button */
}

.generate-now-button:hover {
  background-color: darkred;
}

.generate-now-button:focus {
  outline: none;
}

li {
  display: flex;
  align-items: center;
}
</style>
